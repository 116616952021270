<template>
  <common-trs-page-container>
    <v-row class="mx-n6 mb-5">
      <common-trs-btn
        v-if="financingRoundData.length>0"
        v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM"
        type="primary"
        large
        class="white--text ml-auto"
        text
        :to="{ name: 'NewFinancingRound' }"
      >
        <v-icon class="mr-2">
          mdi-plus-circle
        </v-icon>
        New Financing Round
      </common-trs-btn>
    </v-row>
    <common-page-loader
      v-if="loading"
    />
    <div class="mx-n6">
      <financing-list
        :financing-round-data="financingRoundData"
        @reload="loadData"
      />

      <div
        v-if="financingRoundData.length===0 && !loading"
        class="no-activity content-box custom-scroll-1 text-center"
      >
        <div
          class="trsText--text text-h3 text--lighten-1 grey--text text-center no-activity-error"
        >
          No Financing round found.
        </div>

        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on: tooltip }">
            <common-trs-btn
              v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SECURITY_TERM"
              :to="{ name: 'NewFinancingRound' }"
              type="primary"
              v-on="{ ...tooltip }"
            >
              <v-icon class="mr-2">
                mdi-plus-circle-outline
              </v-icon>
              Create a financing round
            </common-trs-btn>
          </template>
          <span>New financing round</span>
        </v-tooltip>
      </div>
    </div>
  </common-trs-page-container>
</template>
<script>
  import FinancingList from './components/List'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'FinancingRoundIndex',
    components: {
      FinancingList,
    },
    data: () => ({
      financingRoundData: [],
      loading: false,
    }),
    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    mounted () {
      this.loadData()
    },

    methods: {
      async loadData () {
        try {
          this.loading = true
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          this.financingRoundData = resp.data.financing_rounds.sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at)
          })
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
 .content-box {
    height: 400px;
    overflow: auto;
    padding: 2px 10px;
  }
  ::v-deep .no-activity {
    font-size: 18px;
    padding: 80px 40px;
    .no-activity-error {
      margin: 20px;
    }
  }
   ::v-deep .v-btn-dashed {
    background-color: #f1f1f1 !important;
    text-transform: none;
    color: #b4b4b4 !important;
    border: 1px dashed #b4b4b4 !important;
  }
</style>
