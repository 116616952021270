<template>
  <v-container class="pa-0">
    <card-view
      v-for="(item, key) of financingRoundData"
      :key="key"
      :details="item.summaryData"
      :is-editable="isEditAllowed && !item.is_published"
      :is-deleteable="isDeleteAllowed"
      :delete-title="'Delete Financing Round'"
      :delete-message="'Are you sure you want to delete this financing round?'"
      :delete-note="'Please note deleting this round will unlink any associated securities.'"
      @edit="routeToEdit(item)"
      @delete="removeItem(item)"
    >
      <template v-slot:card-title>
        {{ item.name }}
      </template>
      <template v-slot:card-caption-title>
        Invested Capital:
      </template>
      <template v-slot:card-caption-data>
        {{ $currencyWithPrecision(item.invested_capital) }}
      </template>
      <template v-slot:created-on-date>
        {{ $date(item.inserted_at) }}
      </template>
    </card-view>
  </v-container>
</template>
<script>
  import CardView from '@/components/common/captable/components/CardView'
  import * as captableService from '@/components/common/captable/services/captable'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'FinancingList',

    components: {
      CardView,
    },

    props: {
      financingRoundData: {
        type: Array,
      },
    },

    computed: {
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_SECURITY_TERM, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_SECURITY_TERM, this.currentRoles)
      },
    },

    watch: {
      financingRoundData: {
        handler (val) {
          if (val) {
            this.updateSummaryData(val)
          }
        },
        immediate: true,
      },
    },

    methods: {
      goToIssuer () {
        window.open(`${this.CONSTS.ISSUER_CAPTABLE}`, '_blank')
      },
      routeToEdit (item) {
        const route = { name: 'EditFinancingRound', query: { id: item.id } }
        this.$router.push(route)
      },

      async removeItem (itemData) {
        try {
          this.loading = true
          const resp = await captableService.deleteFinancingRoundById(itemData.cap_table_id, itemData.id)
          this.singleFinacingRoundData = resp.data
          this.$store.dispatch('app/message', { text: 'Financing round deleted successfully.' })
          this.$emit('reload')
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Error deleting financing round',
          })
        }
      },

      updateSummaryData (data) {
        data.forEach((item) => {
          const obj = {
            'Granted Shares': this.$number(item.granted_shares),
            'Pre-money Valuation': this.$currencyWithPrecision(item.pre_money_valuation),
            'Initial closing': this.$date(item.initially_closed_on) || 'NA',
            'Price per share': this.$currencyWithPrecision(item.price_per_share),
            'Post-money Valuation': this.$currencyWithPrecision(item.post_money_valuation),
            'Final closing': this.$date(item.finally_closed_on) || 'NA',
          }
          const keys = Object.keys(obj)
          const details = []
          keys.forEach((key) => {
            details.push({
              title: key,
              value: obj[key],
            })
          })
          item.summaryData = [{
            label: 'Summary',
            id: item.id,
            type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS,
            data: details,
          }]
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .card-item {
    border-radius: 0px;
    border: 0px;
    border-top: 3px solid rgba(0, 0, 0, 0.12);
  }
  .response {
    border-left: 5px solid #D7E0E2;
  }
</style>
